@import "var.conf";

/*---------------------------------
Page CSS 2017.07
CSS 担当者名 Document 2017.07
Last Update 2017.07 担当者名
---------------------------------*/
    /*
  404
  -------------------------------------*/
    #Page404 #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  ホーム
  -------------------------------------*/
    #Page.pageIndex #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }

    /*
  トップページ（滋賀用）
  -------------------------------------*/
    #PageShiga.pageIndex #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }

  /*
  aside .sub 共通
  -------------------------------------*/
  aside.sub{
    width:220px;
    float:left;
    margin-right: 50px;
    margin-bottom:100px;
    h3{
      width:100%;
      height: 50px;
      line-height: 50px;
      padding-left: 15px;
      background:url(../img/contents/subnav_bg01.jpg) no-repeat top left;
      color:#fff;
      font-size: 16px;
      font-weight:bold;
    }
    ul{
      border:none;
      &+h3{
        margin-top:20px;
      }
    }
    >ul{
      >li{
        background: #f3f3f3;
        font-size:16px;
        height:auto;
        border-bottom:2px solid #fff;
        a{
          display:block;
          padding:12px 15px;
          height:auto;
          line-height:25px;
          text-decoration:none;
          &:hover{
            opacity:1;
            dl{
              dd{
                text-decoration:underline;
              }
            }
          }
        }
        dl{
          dt{
            color:#d8a668;
            font-size:15px;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            font-style:italic;
          }
          dd{
            color:#064c76;
          }
        }
      }
      &.category{
        li{
          background: #f3f3f3 url(../img/contents/subnav_arrow.png) no-repeat center right 20px;
          background-size: 9px 15px;
          a{
            padding-right:40px;
            &:hover{
              text-decoration:underline;
            }
          }
          &.current{
            a{
              color:#999;
              &:visited{
                color:#999;
              }
            }
          }
        }
      }
      &.monthly{
        >li{
          height:auto;
          border-bottom: 2px solid #fff;
          background: #f3f3f3 url(../img/contents/subnav_icon_close.png) no-repeat top 15px left 15px;
          background-size: 20px 20px;
          font-size:16px;
          padding:0;
          &.minus{
            background: #f3f3f3 url(../img/contents/subnav_icon_open.png) no-repeat top 15px left 15px;
            span{
              color:#999999;
            }
            ul{
              display:block;
            }
          }
          span{
            display: block;
            width:100%;
            height:50px;
            line-height:50px;
            padding-left:44px;
            cursor: pointer;
            color:#333;
            &:hover{
              text-decoration:underline;
            }
          }
          ul{
            padding:0 20px 20px;
            background: #f3f3f3;
            width:auto;
            display:none;
            li{
              border:none;
              padding:14px 25px 12px;
              background:#fff;
              height:auto;
              line-height:1;
              &+li{
                padding-top:0;
              }
              &:last-child{
                padding-bottom:14px;
              }
              a{
                display:inline;
                padding:0;
                line-height:20px;
                font-size:14px;
                color:#1c7c5c;
                background:#fff;
                text-decoration:underline;
                &:visited{
                  color:#1c7c5c;
                }
                &:hover{
                  text-decoration: none;
                }
              }
              &.current a{
                color: #999;
              }
            }
          }
        }
      }
    }
  }

  /*
  pageMove 共通
  -------------------------------------*/
  .pageMove {
    margin:80px 0 140px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-style: italic;
    ul {
      position: relative;
      width: 480px;
      margin: 0 auto;
      text-align: center;
      font-size:0;
      li {
        display: inline-block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        margin:0 5px;
        span {
            display: block;
            width: 40px;
            height: 40px;
            line-height: 40px;
            background: #dcdcdc;
            border: 1px solid #dcdcdc;
            text-align: center;
            color:#fff;
        }
        a {
            display: block;
            width: 40px;
            height: 40px;
            line-height:40px;
            color: #1c7c5c;
            border: 1px solid #339c79;
            text-decoration:none;
            &:visited{
              color:#1c7c5c;
            }
        }
        &:not(.btn){
            a{
              &:hover{
                background:#339c79;
                color:#fff;
                opacity:1;
              }
            }
        }
        &.prev,&.next{
          width:100px;
          margin:0;
          font-size:14px;
          a{
            width:100px;
            color:#fff;
            position:relative;
            background:#339c79;
            &:before{
              content: "";
              width:9px;
              height:40px;
              position: absolute;
              transform: translateZ(0);
              transition-duration: 0.1s;
              transition-property: transform;
              transition-timing-function: ease-out;
            }
            &:hover{
              opacity:0.8;
            }
          }
        }
        &.prev {
            position: absolute;
            top: 0;
            left: 0;
            a{
              //background:#339c79 url(../img/contents/prev_arrow.png) no-repeat center left 12px;
              //background-size:9px 16px;
              padding-left:34px;
              &:before{
                top:0;
                left:12px;
                background:url(../img/contents/prev_arrow.png) no-repeat center left;
                background-size:9px 16px;
              }
              &:hover{
                &:before{
                  transform: translateX(-4px);
                }
              }
            }
        }
        &.next {
          position: absolute;
          top: 0;
          right: 0;
          a{
            padding-right:38px;
            &:before{
              top:0;
              right:12px;
              background:url(../img/contents/next_arrow.png) no-repeat center right;
              background-size:9px 16px;
            }
            &:hover{
              &:before{
                transform: translateX(4px);
              }
            }
          }
        }
      }
    }
  }
  .pageEntry  .pageMove{
    ul{
      width:242px;
      li{
        &.prev,&.next{
          position:static;
        }
        &.prev+li.next{
          margin-left:42px;
        }
      }
    }
  }

  /*
 サポート実績共通
  -------------------------------------*/
#PageCase.pageCategory,
#PageCase.pageMonthly{
    h3.h4,
        h4{
            margin-bottom:0 !important;
        }
}

  /*
  セミナー情報 サポート実績
  一覧共通
  -------------------------------------*/
  #PageTopics #Main,
  #PageCase #Main{
    .contBox{
      div.entryBlock{
        border-top:5px solid #235471;
        padding:75px 0;
        h3.h4,
        h4{
          font-size:22px;
          line-height:32px;
          color:#064c76;
          margin-bottom:35px;
          &:after{
            margin-top:11px;
             margin-bottom: 0  !important;
          }
          a{
            color:#064c76;
            text-decoration:none;
            &:hover{
              opacity:1;
              color:#064c76;
              text-decoration:underline;
            }
            &:visited{
              color:#064c76;
            }
          }
        }
        p{
          &.date{
            color:#d8a668;
            font-size:15px;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            margin-bottom:8px;
            line-height:1;
            font-style: italic;
          }
          &.detailBtn{
            width:220px;
            height:50px;
            line-height:50px;
            margin:30px 0 0 auto;
            a{
              display:block;
              width:100%;
              height:100%;
              border:1px solid #339c79;
              color:#1c7c5c;
              font-size:16px;
              text-align:center;
              text-decoration:none;
              &:visited{
                color:#1c7c5c;
              }
              &:hover{
                background:#3da180;
                color:#fff;
              }
            }
          }
        }
        dl{
          font-size:18px;
          line-height:32px;
          margin:20px 0;
        }
        ul{
          margin:20px 0;
          li{
            font-size:18px;
            line-height:32px;
          }
          &.list{
            li{
              background:url(../img/contents/case_icon.jpg) no-repeat top 11px left;
              background-size:10px 10px;
              padding-left:18px;
            }
          }
        }
        &:last-of-type{
          border-bottom:5px solid #235471;
        }
      }
    }
  }
 /*
  セミナー情報 共通
  -------------------------------------*/
  #PageTopics div#MainImgInner h2{
    padding-top: 0;
    padding-left:60px;
    background: url(../img/contents/index_icon02.png) no-repeat scroll left center;
    background-size: 42px 39px;
    color: #111;
    font-size: 28px;
    line-height: 240px;
  }
  #PageTopics #Container{
    .innerBasic{
      margin-top:70px;
    }
  }
  #PageTopics #Main{
    width: 730px;
    float:right;
  }

    /*
  セミナー情報
  -------------------------------------*/
    #PageTopics.pageIndex #Main {
        #ContBox01 {
          >dl{
            border:1px solid #95bacf;
            padding:39px 25px 30px 39px;
            margin-bottom:80px;
            dt{
              color:#065d91;
              font-size:20px;
              font-weight:bold;
              margin-bottom:15px;
            }
            dd{
              font-size:18px;
              line-height:34px;
            }
          }
        }
    }

    /*
  月別アーカイブ（セミナー情報）
  -------------------------------------*/
    #PageTopics.pageMonthly #Main {
        #ContBox01 {
          h3{
            margin-bottom:66px;
            color:#111111;
            font-size:22px;
            font-weight:bold;
            line-height:50px;
          }
          #ContSubBox01 {
            margin-bottom:80px;
            div.entryBlock{
              &:last-of-type{
                border-bottom:none;
              }
            }
          }
        }
    }

    /*
  記事詳細（セミナー情報）
  -------------------------------------*/
    #PageTopics.pageEntry #Main {
        #ContBox01 {
          #ContSubBox01 {
            margin-bottom:80px;
            div.entryBlock{
              padding-top:0;
              border-top:none;
              border-bottom:5px solid #235471;
              &:hover{
                opacity:1;
              }
            }
          }
        }
    }

   /*
  サポート実績 共通
  -------------------------------------*/
  #PageCase div#MainImgInner h2{
    padding-top: 0;
    padding-left:60px;
    background: url(../img/contents/achievement_icon01.png) no-repeat scroll left center;
    background-size: 50px 38px;
    color: #111;
    font-size: 28px;
    line-height: 240px;
  }
  #PageCase #Container{
    .innerBasic{
      margin-top:70px;
    }
  }
  #PageCase #Main{
    width: 730px;
    float:right;
    .contBox{
      div.companyList{
        margin-bottom:80px;
        p{
          font-size:20px;
          color:#065d91;
          font-weight:bold;
          height:98px;
          line-height:98px;
          padding-left:18px;
          border:1px solid #95bacf;
          position:relative;
        }
        span{
          font-size:14px;
          color:#888888;
          position:absolute;
          top:0;
          bottom:0;
          right:18px;
          margin:auto;
          font-weight:normal;
        }
        ul{
          border:1px solid #95bacf;
          border-top:none;
          overflow: hidden;
          margin:0;
          li{
            padding:14px 18px;
            font-size:18px;
            width:243px;
            float:left;
            line-height:28px;
            border-right:1px solid #95bacf;
            border-bottom:1px solid #95bacf;
            &:nth-child(3n){
              width:242px;
              border-right:none;
            }
            &:last-child,
            &:nth-last-child(2):nth-child(3n+1),
            &:nth-last-child(2):nth-child(3n+2),
            &:nth-last-child(3):nth-child(3n+1){
              border-bottom:none;
            }
          }
        }
      }
    }
  }
    /*
  サポート実績
  -------------------------------------*/
    #PageCase.pageIndex #Main {
        #ContBox01 {
        }
    }

    /*
  カテゴリアーカイブ（サポート実績）
  -------------------------------------*/
    #PageCase.pageCategory #Main {
        #ContBox01 {
        }
    }

    /*
  月別アーカイブ（サポート実績）
  -------------------------------------*/
    #PageCase.pageMonthly #Main {
    }

    /*
  記事詳細（サポート実績）
  -------------------------------------*/
    #PageCase.pageEntry #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }

    /*
  組織案内
  -------------------------------------*/
    #PageClinic.pageIndex #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }

    /*
  お問い合わせ
  -------------------------------------*/
    #PageContact.pageIndex #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }

    /*
  お問い合わせ 確認
  -------------------------------------*/
    #PageContact.pageConfirm #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }

    /*
  お問い合わせ 完了
  -------------------------------------*/
    #PageContact.pageThanks #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }

