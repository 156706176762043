@charset "UTF-8";
/*---------------------------------
Page CSS 2017.07
CSS 担当者名 Document 2017.07
Last Update 2017.07 担当者名
---------------------------------*/
/*
  404
  -------------------------------------*/
/*
  ホーム
  -------------------------------------*/
/*
  トップページ（滋賀用）
  -------------------------------------*/
/*
  aside .sub 共通
  -------------------------------------*/
aside.sub {
  width: 220px;
  float: left;
  margin-right: 50px;
  margin-bottom: 100px; }
  aside.sub h3 {
    width: 100%;
    height: 50px;
    line-height: 50px;
    padding-left: 15px;
    background: url(../img/contents/subnav_bg01.jpg) no-repeat top left;
    color: #fff;
    font-size: 16px;
    font-weight: bold; }
  aside.sub ul {
    border: none; }
    aside.sub ul + h3 {
      margin-top: 20px; }
  aside.sub > ul > li {
    background: #f3f3f3;
    font-size: 16px;
    height: auto;
    border-bottom: 2px solid #fff; }
    aside.sub > ul > li a {
      display: block;
      padding: 12px 15px;
      height: auto;
      line-height: 25px;
      text-decoration: none; }
      aside.sub > ul > li a:hover {
        opacity: 1; }
        aside.sub > ul > li a:hover dl dd {
          text-decoration: underline; }
    aside.sub > ul > li dl dt {
      color: #d8a668;
      font-size: 15px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 600;
      font-style: italic; }
    aside.sub > ul > li dl dd {
      color: #064c76; }
  aside.sub > ul.category li {
    background: #f3f3f3 url(../img/contents/subnav_arrow.png) no-repeat center right 20px;
    background-size: 9px 15px; }
    aside.sub > ul.category li a {
      padding-right: 40px; }
      aside.sub > ul.category li a:hover {
        text-decoration: underline; }
    aside.sub > ul.category li.current a {
      color: #999; }
      aside.sub > ul.category li.current a:visited {
        color: #999; }
  aside.sub > ul.monthly > li {
    height: auto;
    border-bottom: 2px solid #fff;
    background: #f3f3f3 url(../img/contents/subnav_icon_close.png) no-repeat top 15px left 15px;
    background-size: 20px 20px;
    font-size: 16px;
    padding: 0; }
    aside.sub > ul.monthly > li.minus {
      background: #f3f3f3 url(../img/contents/subnav_icon_open.png) no-repeat top 15px left 15px; }
      aside.sub > ul.monthly > li.minus span {
        color: #999999; }
      aside.sub > ul.monthly > li.minus ul {
        display: block; }
    aside.sub > ul.monthly > li span {
      display: block;
      width: 100%;
      height: 50px;
      line-height: 50px;
      padding-left: 44px;
      cursor: pointer;
      color: #333; }
      aside.sub > ul.monthly > li span:hover {
        text-decoration: underline; }
    aside.sub > ul.monthly > li ul {
      padding: 0 20px 20px;
      background: #f3f3f3;
      width: auto;
      display: none; }
      aside.sub > ul.monthly > li ul li {
        border: none;
        padding: 14px 25px 12px;
        background: #fff;
        height: auto;
        line-height: 1; }
        aside.sub > ul.monthly > li ul li + li {
          padding-top: 0; }
        aside.sub > ul.monthly > li ul li:last-child {
          padding-bottom: 14px; }
        aside.sub > ul.monthly > li ul li a {
          display: inline;
          padding: 0;
          line-height: 20px;
          font-size: 14px;
          color: #1c7c5c;
          background: #fff;
          text-decoration: underline; }
          aside.sub > ul.monthly > li ul li a:visited {
            color: #1c7c5c; }
          aside.sub > ul.monthly > li ul li a:hover {
            text-decoration: none; }
        aside.sub > ul.monthly > li ul li.current a {
          color: #999; }

/*
  pageMove 共通
  -------------------------------------*/
.pageMove {
  margin: 80px 0 140px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-style: italic; }
  .pageMove ul {
    position: relative;
    width: 480px;
    margin: 0 auto;
    text-align: center;
    font-size: 0; }
    .pageMove ul li {
      display: inline-block;
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      margin: 0 5px; }
      .pageMove ul li span {
        display: block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        background: #dcdcdc;
        border: 1px solid #dcdcdc;
        text-align: center;
        color: #fff; }
      .pageMove ul li a {
        display: block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        color: #1c7c5c;
        border: 1px solid #339c79;
        text-decoration: none; }
        .pageMove ul li a:visited {
          color: #1c7c5c; }
      .pageMove ul li:not(.btn) a:hover {
        background: #339c79;
        color: #fff;
        opacity: 1; }
      .pageMove ul li.prev, .pageMove ul li.next {
        width: 100px;
        margin: 0;
        font-size: 14px; }
        .pageMove ul li.prev a, .pageMove ul li.next a {
          width: 100px;
          color: #fff;
          position: relative;
          background: #339c79; }
          .pageMove ul li.prev a:before, .pageMove ul li.next a:before {
            content: "";
            width: 9px;
            height: 40px;
            position: absolute;
            transform: translateZ(0);
            transition-duration: 0.1s;
            transition-property: transform;
            transition-timing-function: ease-out; }
          .pageMove ul li.prev a:hover, .pageMove ul li.next a:hover {
            opacity: 0.8; }
      .pageMove ul li.prev {
        position: absolute;
        top: 0;
        left: 0; }
        .pageMove ul li.prev a {
          padding-left: 34px; }
          .pageMove ul li.prev a:before {
            top: 0;
            left: 12px;
            background: url(../img/contents/prev_arrow.png) no-repeat center left;
            background-size: 9px 16px; }
          .pageMove ul li.prev a:hover:before {
            transform: translateX(-4px); }
      .pageMove ul li.next {
        position: absolute;
        top: 0;
        right: 0; }
        .pageMove ul li.next a {
          padding-right: 38px; }
          .pageMove ul li.next a:before {
            top: 0;
            right: 12px;
            background: url(../img/contents/next_arrow.png) no-repeat center right;
            background-size: 9px 16px; }
          .pageMove ul li.next a:hover:before {
            transform: translateX(4px); }

.pageEntry .pageMove ul {
  width: 242px; }
  .pageEntry .pageMove ul li.prev, .pageEntry .pageMove ul li.next {
    position: static; }
  .pageEntry .pageMove ul li.prev + li.next {
    margin-left: 42px; }

/*
 サポート実績共通
  -------------------------------------*/
#PageCase.pageCategory h3.h4,
#PageCase.pageCategory h4,
#PageCase.pageMonthly h3.h4,
#PageCase.pageMonthly h4 {
  margin-bottom: 0 !important; }

/*
  セミナー情報 サポート実績
  一覧共通
  -------------------------------------*/
#PageTopics #Main .contBox div.entryBlock,
#PageCase #Main .contBox div.entryBlock {
  border-top: 5px solid #235471;
  padding: 75px 0; }
  #PageTopics #Main .contBox div.entryBlock h3.h4,
  #PageTopics #Main .contBox div.entryBlock h4,
  #PageCase #Main .contBox div.entryBlock h3.h4,
  #PageCase #Main .contBox div.entryBlock h4 {
    font-size: 22px;
    line-height: 32px;
    color: #064c76;
    margin-bottom: 35px; }
    #PageTopics #Main .contBox div.entryBlock h3.h4:after,
    #PageTopics #Main .contBox div.entryBlock h4:after,
    #PageCase #Main .contBox div.entryBlock h3.h4:after,
    #PageCase #Main .contBox div.entryBlock h4:after {
      margin-top: 11px;
      margin-bottom: 0  !important; }
    #PageTopics #Main .contBox div.entryBlock h3.h4 a,
    #PageTopics #Main .contBox div.entryBlock h4 a,
    #PageCase #Main .contBox div.entryBlock h3.h4 a,
    #PageCase #Main .contBox div.entryBlock h4 a {
      color: #064c76;
      text-decoration: none; }
      #PageTopics #Main .contBox div.entryBlock h3.h4 a:hover,
      #PageTopics #Main .contBox div.entryBlock h4 a:hover,
      #PageCase #Main .contBox div.entryBlock h3.h4 a:hover,
      #PageCase #Main .contBox div.entryBlock h4 a:hover {
        opacity: 1;
        color: #064c76;
        text-decoration: underline; }
      #PageTopics #Main .contBox div.entryBlock h3.h4 a:visited,
      #PageTopics #Main .contBox div.entryBlock h4 a:visited,
      #PageCase #Main .contBox div.entryBlock h3.h4 a:visited,
      #PageCase #Main .contBox div.entryBlock h4 a:visited {
        color: #064c76; }
  #PageTopics #Main .contBox div.entryBlock p.date,
  #PageCase #Main .contBox div.entryBlock p.date {
    color: #d8a668;
    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    margin-bottom: 8px;
    line-height: 1;
    font-style: italic; }
  #PageTopics #Main .contBox div.entryBlock p.detailBtn,
  #PageCase #Main .contBox div.entryBlock p.detailBtn {
    width: 220px;
    height: 50px;
    line-height: 50px;
    margin: 30px 0 0 auto; }
    #PageTopics #Main .contBox div.entryBlock p.detailBtn a,
    #PageCase #Main .contBox div.entryBlock p.detailBtn a {
      display: block;
      width: 100%;
      height: 100%;
      border: 1px solid #339c79;
      color: #1c7c5c;
      font-size: 16px;
      text-align: center;
      text-decoration: none; }
      #PageTopics #Main .contBox div.entryBlock p.detailBtn a:visited,
      #PageCase #Main .contBox div.entryBlock p.detailBtn a:visited {
        color: #1c7c5c; }
      #PageTopics #Main .contBox div.entryBlock p.detailBtn a:hover,
      #PageCase #Main .contBox div.entryBlock p.detailBtn a:hover {
        background: #3da180;
        color: #fff; }
  #PageTopics #Main .contBox div.entryBlock dl,
  #PageCase #Main .contBox div.entryBlock dl {
    font-size: 18px;
    line-height: 32px;
    margin: 20px 0; }
  #PageTopics #Main .contBox div.entryBlock ul,
  #PageCase #Main .contBox div.entryBlock ul {
    margin: 20px 0; }
    #PageTopics #Main .contBox div.entryBlock ul li,
    #PageCase #Main .contBox div.entryBlock ul li {
      font-size: 18px;
      line-height: 32px; }
    #PageTopics #Main .contBox div.entryBlock ul.list li,
    #PageCase #Main .contBox div.entryBlock ul.list li {
      background: url(../img/contents/case_icon.jpg) no-repeat top 11px left;
      background-size: 10px 10px;
      padding-left: 18px; }
  #PageTopics #Main .contBox div.entryBlock:last-of-type,
  #PageCase #Main .contBox div.entryBlock:last-of-type {
    border-bottom: 5px solid #235471; }

/*
  セミナー情報 共通
  -------------------------------------*/
#PageTopics div#MainImgInner h2 {
  padding-top: 0;
  padding-left: 60px;
  background: url(../img/contents/index_icon02.png) no-repeat scroll left center;
  background-size: 42px 39px;
  color: #111;
  font-size: 28px;
  line-height: 240px; }

#PageTopics #Container .innerBasic {
  margin-top: 70px; }

#PageTopics #Main {
  width: 730px;
  float: right; }

/*
  セミナー情報
  -------------------------------------*/
#PageTopics.pageIndex #Main #ContBox01 > dl {
  border: 1px solid #95bacf;
  padding: 39px 25px 30px 39px;
  margin-bottom: 80px; }
  #PageTopics.pageIndex #Main #ContBox01 > dl dt {
    color: #065d91;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px; }
  #PageTopics.pageIndex #Main #ContBox01 > dl dd {
    font-size: 18px;
    line-height: 34px; }

/*
  月別アーカイブ（セミナー情報）
  -------------------------------------*/
#PageTopics.pageMonthly #Main #ContBox01 h3 {
  margin-bottom: 66px;
  color: #111111;
  font-size: 22px;
  font-weight: bold;
  line-height: 50px; }

#PageTopics.pageMonthly #Main #ContBox01 #ContSubBox01 {
  margin-bottom: 80px; }
  #PageTopics.pageMonthly #Main #ContBox01 #ContSubBox01 div.entryBlock:last-of-type {
    border-bottom: none; }

/*
  記事詳細（セミナー情報）
  -------------------------------------*/
#PageTopics.pageEntry #Main #ContBox01 #ContSubBox01 {
  margin-bottom: 80px; }
  #PageTopics.pageEntry #Main #ContBox01 #ContSubBox01 div.entryBlock {
    padding-top: 0;
    border-top: none;
    border-bottom: 5px solid #235471; }
    #PageTopics.pageEntry #Main #ContBox01 #ContSubBox01 div.entryBlock:hover {
      opacity: 1; }

/*
  サポート実績 共通
  -------------------------------------*/
#PageCase div#MainImgInner h2 {
  padding-top: 0;
  padding-left: 60px;
  background: url(../img/contents/achievement_icon01.png) no-repeat scroll left center;
  background-size: 50px 38px;
  color: #111;
  font-size: 28px;
  line-height: 240px; }

#PageCase #Container .innerBasic {
  margin-top: 70px; }

#PageCase #Main {
  width: 730px;
  float: right; }
  #PageCase #Main .contBox div.companyList {
    margin-bottom: 80px; }
    #PageCase #Main .contBox div.companyList p {
      font-size: 20px;
      color: #065d91;
      font-weight: bold;
      height: 98px;
      line-height: 98px;
      padding-left: 18px;
      border: 1px solid #95bacf;
      position: relative; }
    #PageCase #Main .contBox div.companyList span {
      font-size: 14px;
      color: #888888;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 18px;
      margin: auto;
      font-weight: normal; }
    #PageCase #Main .contBox div.companyList ul {
      border: 1px solid #95bacf;
      border-top: none;
      overflow: hidden;
      margin: 0; }
      #PageCase #Main .contBox div.companyList ul li {
        padding: 14px 18px;
        font-size: 18px;
        width: 243px;
        float: left;
        line-height: 28px;
        border-right: 1px solid #95bacf;
        border-bottom: 1px solid #95bacf; }
        #PageCase #Main .contBox div.companyList ul li:nth-child(3n) {
          width: 242px;
          border-right: none; }
        #PageCase #Main .contBox div.companyList ul li:last-child, #PageCase #Main .contBox div.companyList ul li:nth-last-child(2):nth-child(3n+1), #PageCase #Main .contBox div.companyList ul li:nth-last-child(2):nth-child(3n+2), #PageCase #Main .contBox div.companyList ul li:nth-last-child(3):nth-child(3n+1) {
          border-bottom: none; }

/*
  サポート実績
  -------------------------------------*/
/*
  カテゴリアーカイブ（サポート実績）
  -------------------------------------*/
/*
  月別アーカイブ（サポート実績）
  -------------------------------------*/
/*
  記事詳細（サポート実績）
  -------------------------------------*/
/*
  組織案内
  -------------------------------------*/
/*
  お問い合わせ
  -------------------------------------*/
/*
  お問い合わせ 確認
  -------------------------------------*/
/*
  お問い合わせ 完了
  -------------------------------------*/
